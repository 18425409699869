import { useState } from "react";
import styles from "./Ambassadors.module.css";
import DiscountSection from "./DiscountSection";
import AmbassadorList from "./AmbassadorList";

const TariffCard = ({ name, amount, ambassadors, selected, onClick }) => (
    <div
        className={`${styles.card} ${selected ? styles.selected : ""}`}
        onClick={onClick}
    >
        <h3>{name}</h3>
        <p className={styles.amount}>{amount} ₸</p>
        <p className={styles.ambassadors}>{ambassadors} амбассадоров</p>
    </div>
);

const AmbassadorsPage = () => {
    const [selectedTariff, setSelectedTariff] = useState(null);
    const [purchaseHistory, setPurchaseHistory] = useState([]);

    const tariffs = [
        { name: "Тариф A", amount: 12000, ambassadors: 3 },
        { name: "Тариф B", amount: 15000, ambassadors: 5 },
        { name: "Тариф C", amount: 18000, ambassadors: 7 },
        { name: "Тариф D", amount: 20000, ambassadors: 9 },
    ];

    const handlePurchase = (data) => {
        setPurchaseHistory((prev) => [
            ...prev,
            { ...data, date: new Date().toLocaleString() }
        ]);
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Тарифы</h1>

            <div className={styles.cardContainer}>
                {tariffs.map((tariff, index) => (
                    <TariffCard
                        key={index}
                        {...tariff}
                        selected={selectedTariff === index}
                        onClick={() => setSelectedTariff(index)}
                    />
                ))}

                <div className={styles.remainingBox}>
                    <strong style={{ display: "block", marginBottom: "16px" }}>
                        {tariffs[selectedTariff].name}
                    </strong>
                    <div className={styles.label}>Осталось от тарифа</div>
                    <div className={styles.amount}>
                        {
                            Math.max(
                                tariffs[selectedTariff].amount -
                                purchaseHistory
                                    .filter(p => p.tariffName === tariffs[selectedTariff].name)
                                    .reduce((sum, p) => sum + p.amount, 0)
                                , 0
                            )
                        } ₸
                    </div>
                </div>
            </div>

            <div className={styles.dropdownArea}>
                {selectedTariff !== null ? (
                    <div>
                        <div className={styles.discountWrapper}>

                            <DiscountSection onPurchase={(data) => handlePurchase({ ...data, tariffName: tariffs[selectedTariff].name })} />
                        </div>
                        <AmbassadorList purchaseHistory={purchaseHistory} />

                        {purchaseHistory.length > 0 && (
                            <div className={styles.historySection}>
                                <h3>История продаж</h3>
                                <ul className={styles.historyList}>
                                    {purchaseHistory.map((item, i) => (
                                        <li key={i} className={styles.historyItem}>
                                            <span>{item.date}</span> —
                                            Амбассадор <strong>{item.name}</strong> продал товар на <strong>{item.amount} ₸</strong> со скидкой <strong>{item.discount}%</strong>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                ) : (
                    <p className={styles.dropdownPlaceholder}>
                        Выберите тариф, чтобы увидеть детали
                    </p>
                )}
            </div>

        </div>
    );
};

export default AmbassadorsPage;