import React, { useState } from 'react';
import styles from './OrderInvoiceCard.module.css';
import ChatButton from '../../components/ChatButton/ChatButton';

const OrderInvoiceCard = ({ order, onSetInvoice }) => {
    const [invoiceInput, setInvoiceInput] = useState('');
    const isAddressMissing = order.address.trim() === "Самовывоз";

    const handleSubmit = () => {
        if (!invoiceInput || isNaN(invoiceInput)) {
            alert('Введите корректную сумму');
            return;
        }
        onSetInvoice(invoiceInput); // теперь только по клику
    };

    return (
        <div className={styles.card}>
            <div className={styles.orderInfo}>
                <p><strong>Логин:</strong> {order.login}</p>
                <p><strong>Телефон:</strong> {order.phone}</p>
                <p><strong>Город:</strong> {order.city}</p>
                <p><strong>Адрес:</strong> {order.address}</p>
                <p><strong>Количество:</strong> {order.count}</p>
                <p><strong>Сумма товара:</strong> {order.amount} {order.currency}</p>
            </div>

            {isAddressMissing && (
                <p className={styles.disabledNote}>
                    ❗ Адрес доставки отсутствует. Выставить счёт невозможно.
                </p>
            )}

            <div className={styles.invoiceInput}>
                <input
                    type="number"
                    value={invoiceInput}
                    placeholder="Цена за доставку"
                    onChange={(e) => setInvoiceInput(e.target.value)}
                    disabled={isAddressMissing}
                />

                <button
                    className={styles.submitButton}
                    onClick={handleSubmit}
                    disabled={isAddressMissing}
                >
                    Выставить счёт
                </button>

                <div className={styles.commissionWrapper}>
                    <label>Комиссия (3%):</label>
                    <input
                        type="number"
                        readOnly
                        value={Math.round((+order.amount + +invoiceInput) * 0.03) || 0}
                        className={styles.commissionInput}
                    />
                </div>

                <div className={styles.commissionWrapper}>
                    <label><strong>Итого к оплате:</strong></label>
                    <input
                        type="number"
                        readOnly
                        value={
                            (+order.amount || 0) +
                            (+invoiceInput || 0) +
                            Math.round((+order.amount + +invoiceInput) * 0.05)
                        }
                        className={styles.commissionInput}
                    />
                </div>
            </div>

            <p className={styles.note}>
                💬 Покупатель оформил заявку на товар. Продавец должен <strong>указать цену доставки</strong>, после чего будет рассчитана комиссия и общая сумма.<br />
                Нажмите «Выставить счёт», чтобы передать заявку в раздел <strong>«Ожидается оплата»</strong>.
            </p>

            <ChatButton userId={order.login} />
        </div>
    );
};

export default OrderInvoiceCard;