import Navbar from './components/Navbar/Navbar';
import Background from './components/Background/Background';
import Footer from './components/Footer/Footer';

import NotificationContainer from "./components/NotificationContainer/NotificationContainer";

import Homepage from './pages (NOT USED)/Homepage'
import Products from './features/ProductsFolder/Products';
import Advertisements from './features/AdvertisementFolder/AdvertisementFolder';
import Courier from './features/CourierFolder/CourierPage';
import ChatPage from './features/ChatFolder/ChatPage';

import Mailing from './features/MailingFolder/Mailing';
import Analytics from './features/AnalyticsFolder/Analytics';
import Dynamics from './features/DynamicsFolder/Dynamics';
import Ambassadors from './features/AmbassadorsFolder/Ambassadors';
import Partners from './features/PartnersFolder/Partners';
import Detection from './features/DetectionFolder/Detection';
import Balance from './features/BalanceFolder/Balance';
import Configuration from './features/ConfigurationFolder/Configuration';
import LegalConfiguration from './features/LegalConfigurationFolder/LegalConfiguration';
import AuthLogin from './features/AuthLogin/AuthLogin';
import AuthSignup from './features/AuthSignup/AuthSignup';
import PersonalRouter from './features/PersonalRouterFolder/PersonalRouter';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useRef, useEffect } from "react";

function App() {
  const notifRef = useRef(null);

  useEffect(() => {
    window.__addNotif = (message, duration) => {
      if (notifRef.current) {
        notifRef.current(message, duration);
      }
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <Navbar />
        <Background />

        <div className="content">
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route path="/apply" element={<Products />} />
            <Route path="/advertisements" element={<Advertisements />} />
            <Route path="/learning" element={<Advertisements />} />
            <Route path="/courier" element={<Courier />} />
            <Route path="/chitchat" element={<ChatPage />} />

            <Route path="/mailing" element={<Mailing />} />
            <Route path="/detection" element={<Detection />} />

            <Route path="/analytics" element={<Analytics />} />
            <Route path="/dynamics" element={<Dynamics />} />
            <Route path="/ambassadors" element={<Ambassadors />} />
            <Route path="/partners" element={<Partners />} />

            <Route path="/balance" element={<Balance />} />

            <Route path="/configuration" element={<Configuration />} />
            <Route path="/legalconfiguration" element={<LegalConfiguration />} />

            <Route path="/auth" element={<AuthLogin />} />
            <Route path="/signup" element={<AuthSignup />} />

            <Route path="/personal_routers" element={<PersonalRouter />} />
          </Routes>
        </div>

        <NotificationContainer ref={notifRef} />

        <Footer />
      </Router>
    </div>
  );
}

export default App;